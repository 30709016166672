import React, { useEffect, useState } from "react";
import { League } from "./league";
import { Tournament } from "./tournament";
import { useParams } from "react-router-dom";
import { getEventByUrlName } from "../../firebase/api/events.api";

export const Event = () => {
  const { name } = useParams(); // Get the event name from the URL params
  const [event, setEvent] = useState(null); // State to store the fetched event
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const fetchedEvent = await getEventByUrlName(name); // Fetch event data
        console.log(fetchedEvent)
        setEvent(fetchedEvent); // Update state with fetched data
      } catch (err) {
        console.error("Error fetching event:", err);
        setError("Failed to fetch event data");
      }
    };

    if (name) {
      fetchEvent(); // Fetch data only if `name` exists
    }
  }, [name]); // Re-run effect if `name` changes

  const renderUpdater = () => {
    if (error) {
      return <div>{error}</div>;
    }

    if (!event) {
      return <div>Loading...</div>; // Show loading state
    }

    switch (event.type+"") {
      case "0":
        return <Tournament name={name} type={event.type} />;
      case "1":
        return <League name={name} type={event.type} />;
      default:
        return <div>Invalid type</div>;
    }
  };

  return <>{renderUpdater()}</>; // Ensure the result is rendered as JSX
};
